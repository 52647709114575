import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { UiLineSeparator } from '@uireact/separator';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "custom-dialog-controller"
    }}>{`Custom dialog controller`}</h1>
    <UiLineSeparator mdxType="UiLineSeparator" />
    <br />
    <p>{`Creating a custom dialog controller is possible so you can provide with you own strategy for the dialog handling.`}</p>
    <p><em parentName="p">{`5 mins read`}</em></p>
    <p>{`Steps:`}</p>
    <ol>
      <li parentName="ol">{`Create a dialog controller using the `}<a parentName="li" {...{
          "href": "https://github.com/inavac182/uireact/blob/main/packages/dialog/src/provider/dialogs-provider.ts#L3"
        }}>{`IDialogController`}</a>{` type.`}</li>
      <li parentName="ol">{`Set the `}<a parentName="li" {...{
          "href": "https://github.com/inavac182/uireact/blob/main/packages/dialog/src/provider/dialogs-provider.ts#L13"
        }}>{`UiDialogsControllerContext`}</a>{` with your custom dialog controller.`}</li>
      <li parentName="ol">{`Render your dialog.`}</li>
    </ol>
    <br />
    <iframe src="https://giphy.com/embed/zOvBKUUEERdNm" width="300" height="200" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    <p><em parentName="p">{`We think a custom dialogs controller it's easy to set up, please let us know if is not 🫣`}</em></p>
    <h2 {...{
      "id": "dialog-controller-"
    }}>{`Dialog Controller 🎮`}</h2>
    <br />
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/dialog/src/provider/dialogs-provider.ts#L3"
      }}>{`IDialogController`}</a>{`
type is exported from the `}<inlineCode parentName="p">{`@uireact/dialog`}</inlineCode>{` package and should be used to correctly type the controller object so it matches the expectation of the dialogs context.`}</p>
    <p>{`This controller has to provide with this actions:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`{
  openDialog: (dialogId: string) => void;
    ▶︎ This function will get executed every time a dialog is OPENED and will pass the dialogId of the dialog.
  closeDialog: (dialogId: string) => void;
    ▶︎ This function will get executed every time a dialog is CLOSED and will pass the dialogId of the dialog.
  openedDialogs: string[];
    ▶︎ This property has to keep track of the opened dialogs and is used inside the UiDialog to determine if a dialog should be visible.
}
`}</code></pre>
    <p>{`The default `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/src/providers/dialogs-controller.ts#LL4C14-L4C33"
      }}>{`dialog controller`}</a>{` only uses a React state to keep track of the opened dialogs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React from 'react';
import { IDialogController } from '@uireact/dialog';

// In this example we have gone with a custom hook that will provide with the controller object.
export const useDialogController = (): IDialogController => {
  /**
   * This state is what will keep all the opened dialogs, as each dialog is created using a dialog id string all we have to do is
   * keep track of the list of strings for dialogs that are opened.
   */
  const [openedDialogs, setOpenedDialogs] = React.useState<string[]>([]);

  const openDialog = React.useCallback(
    (dialogId: string) => {
      const found = openedDialogs.filter((id) => id === dialogId);

      if (!found || found.length === 0) {
        const newOpenedDialogs = [...openedDialogs, dialogId];
        setOpenedDialogs(newOpenedDialogs);
      }
    },
    [openedDialogs, setOpenedDialogs]
  );

  const closeDialog = React.useCallback(
    (dialogId: string) => {
      const newOpenedDialogs = openedDialogs.filter((id) => id !== dialogId);
      setOpenedDialogs(newOpenedDialogs);
    },
    [openedDialogs, setOpenedDialogs]
  );

  return {
    openDialog,
    closeDialog,
    openedDialogs,
  };
};
`}</code></pre>
    <br />
    <h2 {...{
      "id": "uidialogscontrollercontext-️"
    }}>{`UiDialogsControllerContext 🎛️`}</h2>
    <p>{`Once you have created your custom dialog controller now we have to set it into the `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/dialog/src/provider/dialogs-provider.ts#LL13C14-L13C40"
      }}>{`UiDialogsControllerContext`}</a></p>
    <h3 {...{
      "id": "using-uiview"
    }}>{`Using UiView`}</h3>
    <p>{`We highly suggest using the `}<a parentName="p" {...{
        "href": "https://uireact.io/packages-view-docs-view"
      }}>{`UiView`}</a>{` component as it sets global providers / styles.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React from 'react';

import { DefaultTheme, ThemeColor } from '@uireact/foundation';
import { UiDialogsControllerContext } from '@uireact/dialog';
import { UiView } from '@uireact/view';

import { useDialogController } from './providers';

export const MyAppWrapper: React.FC = () => {
  // In the example above we created this hook, so now we can use it to get the controller:
  const dialogController = useDialogController();

  // The prop dialogController is the one that gets the controller object
  return (
    <UiView theme={DefaultTheme} selectedTheme={ThemeColor.light} dialogController={dialogController}>
      ... Your app tree ...
    </UiView>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "manually-set-up"
    }}>{`Manually set up`}</h3>
    <p>{`If for any reason you won't be using the `}<inlineCode parentName="p">{`UiView`}</inlineCode>{` then you can always get the dialogs context set up manually by importing the
context provider and setting its value like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React from 'react';

import { UiDialogsControllerContext } from '@uireact/dialog';

export const MyAppWrapper: React.FC = () => {
  // Using the hook that was created in the step before we can get the dialog controller
  const dialogController = useDialogController();

  return (
    <UiDialogsControllerContext.Provider value={dialogController}>
      ... Your App tree ...
    </UiDialogsControllerContext.Provider>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "-use-a-dialog-"
    }}>{`🏁 Use a dialog 🚀`}</h2>
    <p>{`Now that the custom controller has been created you can then start using the `}<a parentName="p" {...{
        "href": "https://uireact.io/packages-dialog-docs-docs"
      }}>{`UiDialog`}</a>{` component.`}</p>
    <iframe src="https://giphy.com/embed/6ib6KPmkeAjDTxMxij" width="300" height="300" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      